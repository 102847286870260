:root {
  --toastify-color-success: rgb(34 197 94);
  --toastify-color-error: rgb(185 28 28);
  --fifa-red:#9a1032;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-position:  center center;
  background-repeat:  no-repeat;
  background-attachment:  fixed;
  background-size:cover;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  /* background-blend-mode: multiply; */
}
#root {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}
@media (max-width: 480px) {
  body {
    background-image: url("images/fifa-mobile.gif");
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  body {
    background-image:url("images/bg-tablet.jpg");
  }
}

@media (min-width: 1025px) {
  body {
    background-image: url("images/bg-desktop.jpg");
  }
}
.selected {
  @apply bg-emerald-600 text-white
}

.form-control {
  @apply focus:ring-2 focus:ring-orange-500 focus:outline-none appearance-none w-full leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 px-3 ring-1 ring-slate-200 shadow-sm;
}
.form-label {
  @apply text-sm leading-6 font-medium text-slate-600 group-focus-within:text-orange-500 mb-1 inline-block;
}
.btn {
  @apply bg-orange-600 shadow text-white leading-6 font-medium py-2 px-3 rounded-lg disabled:bg-orange/50 disabled:shadow-none;
}
.hasError {
  @apply text-rose-600 text-xs leading-4 font-medium py-1;
}
select.form-control {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
[type="checkbox"],
[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #f7941e;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}

[type="checkbox"] {
  border-radius: 0px;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus,
[type="radio"]:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #f7941e;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover,
[type="checkbox"]:checked:focus,
[type="radio"]:checked:hover,
[type="radio"]:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}

[type="checkbox"]:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type="checkbox"]:indeterminate:hover,
[type="checkbox"]:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}
.bg-round {
  background-position: right center;
  background-size: cover;
  text-shadow: -1px 1px 3px #000;
}
.bg-round1 {
  background-image: url('images/round1.png');
}
.bg-round2 {
  background-image: url('images/round2.png');
}
.bg-round3 {
  background-image: url('images/round3.png');
}
.bg-round4 {
  background-image: url('images/goldenBoot.png');
}
.bg-round5 {
  background-image: url('images/round4.png');
}
.bg-round6 {
  background-image: url('images/round5.png');
}
.bg-round-goldenBoot {
  background-image: url('images/goldenBoot.png');
}
.customRadio input[type="radio"]:checked + div {
  @apply bg-emerald-600 text-white !shadow-md rounded
}
.captchaContainer {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
}